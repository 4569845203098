import React from "react"
import { graphql } from "gatsby"

import { PostProvider } from "../context/PostContext"

import ComponentComposer from "../components/ComponentComposer"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import ModuleSpacing from "../components/ModuleSpacing"

const BlogPostTemplate = ({ data }) => (
  <Layout>
    <SEO
      seoBody={data.wordpressPost.yoast_head}
      title={data.wordpressPost.title}
      description={data.wordpressPost.excerpt}
    />
    <Hero
      categories={data.wordpressPost.categories}
	  type="post"
      fallbackTitle={data.wordpressPost.title}
	  hasAcf={data?.wordpressPost?.acf?.hero?.headline}
	  usesWPContent={data?.wordpressPost?.content !== ""}
      {...data.wordpressPost.acf.hero}
    />
	<PostProvider value={data.allWordpressPost}>
    {data.wordpressPost.children.map((item, index) => {
      const previousComponent =
        index > 0 ? data.wordpressPost.children[index - 1] : null
      return (
        <ComponentComposer
          key={index}
          componentName={item.__typename}
          previousComponentName={previousComponent?.__typename}
          id={item.id}
          index={index}
          {...item}
        />
      )
    })}
	</PostProvider>
    <Container>
      <ModuleSpacing bottom={false}>
        <div
          className="post"
          dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
        />
      </ModuleSpacing>
    </Container>
  </Layout>
)
export default BlogPostTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      yoast_head
      title
      categories {
		slug
        name
      }
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      author {
        name
      }
      acf {
        external_link
        hero {
          image_alignment
          headline
          copy
          cta {
            target
            title
            url
          }
          background_image {
            alt_text
            localFile {
              url
            }
          }
          foreground_texture
		  background_texture
        }
      }
      children {
        __typename
        id
        ...CtaBlockFragment
        ...ImageStackFragment
        ...LogoGridFragment
        ...MediaBlockFragment
        ...QuoteFragment
        ...RelatedContentFragment
        ...ToutFragment
        ...FullScreenToutFragment
        ...KeyStatsFragment
        ...TextBlockFragment
        ...FormBlockFragment
		...FeaturedPostsFragment
		...SimpleTextFragment
      }
    }
	allWordpressPost {
		edges {
		  node {
			acf {
			  external_link
			  og_image_link
			}
			title
			tags {
			  name
			  slug
			}
			categories {
			  name
			  slug
			}
			featured_media {
			  alt_text
			  localFile {
				url
			  }
			}
			slug
			excerpt
			wordpress_id
		  }
		}
	  }
  }
`
